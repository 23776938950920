.header {
	position: relative;
	background: #5273e6;
	min-height: 160px;

	.rest {
		position: relative;
		z-index: 1;
	}

	margin-bottom: 50px;

	.bottom-title {
		width: 230px;
		height: 40px;
		border-radius: 30px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #5273e6;
		font-family: "Roboto Geo Caps";
		font-size: 16px;
		margin: 0px auto;
		position: absolute;
		bottom: 0;
		right: 50%;
		left: 50%;
		transform: translate(-50%, 50%);

		&.bottom-title-responsive {
			@media screen and (max-width: 591px) {
				border-radius: initial;
				box-shadow: initial;
				top: 40px;
				bottom: initial;
				background-color: initial;
				color: #fff;
			}
		}
	}

	@media screen and (max-width: 600px) {
		min-height: 78px;
	}
}

.half-moon {
	background: url("../../styles/img/half-circle.svg") bottom center, white;
	background-size: 100% 100%;
	width: 100%;
	height: 102px;
	position: absolute;
	z-index: 0;
	bottom: 0px;

	@media screen and (max-width: 600px) {
		height: 38px;
	}
}

.mainTitle {
	margin: 0 auto;
	color: #fff;
	letter-spacing: 1px;
	font-size: 20px;
	margin-bottom: 29px;
	font-weight: bold;
	font-family: "Roboto Geo Caps";
	text-align: center;
}
