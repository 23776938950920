.boxContainer{
  border-radius: 5px;
  &.defaultBackground{
    background: white;
  }
  &.bordered{
  border: 1px solid #808080;
  }
  &.shadow{
    box-shadow: 0px 3px 6px #00000040;;
  }
}