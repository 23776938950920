.edu-fair {
	@media screen and (max-width: 1123px) {
		border-radius: 8% 8% 20px 20px;
	}
	.imageContainer {
		display: inline-block;
		vertical-align: middle;

		@media screen and (max-width: 1123px) {
			display: block;
			margin-bottom: 30px;
		}
	}

	img {
		margin-left: -4px;
		width: 400px;
		height: 600px;
		margin-bottom: -14px;
		margin-top: -4px;
		transform: translate(-1px, -1px);

		@media screen and (max-width: 1123px) {
			display: block;
			width: 100%;
			height: 20%;
			margin-left: -0;
			transform: translate(0, -2px);
		}
	}

	.contentDiv {
		display: inline-block;
		padding-left: 35px;
		vertical-align: middle;
		width: 600px;

		@media screen and (max-width: 1123px) {
			display: block;
			width: 100%;
			height: 100%;
			padding-left: 5%;
		}
	}

	.doNotMiss {
		width: 220px;
		height: 65px;
		display: inline-block;
		background-color: #ff3304;
		color: white;
		font-family: "Roboto Geo MT Bold";
		font-size: 26px;
		font-weight: bold;
		border: none;
		text-align: left;
		padding-left: 10px;
		line-height: 1;
		&:focus {
			outline: none;
		}
	}

	h3 {
		font-weight: bold;
		font-family: "Roboto Geo Nus";
		margin: 8px 0px;
		text-align: left;
		font-size: 19px;
	}

	p {
		font-family: "Roboto Geo Nus";
		text-align: left;
		font-size: 16px;
		padding-right: 30px;
	}

	ul {
		list-style: none; /* Remove default bullets */
		display: block;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
		margin-left: -23px;
	}

	ul li::before {
		content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: #ffcd00; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		display: inline-block; /* Needed to add space between the bullet and the text */
		width: 0.5em; /* Also needed for space (tweak if needed) */
		margin-left: -1rem; /* Also needed for space (tweak if needed) */
		font-size: 30px;
		vertical-align: middle;
		line-height: 1;
	}

	ul li span {
		font-size: 16px;
	}

	.buttonsContainer {
		text-align: right;
		padding-right: 35px;
		@media screen and (max-width: 1123px) {
			text-align: center;
			padding-bottom: 20px;
		}
		span {
			font-size: 16px;
			font-family: "Roboto Geo MT Bold";
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}

			@media screen and (max-width: 1123px) {
				text-align: center;
			}
		}
		button {
			width: 300px;
			height: 55px;
			background-color: #ffcd00;
			font-size: 22px;
			border-radius: 20px;
			border: none;
			font-family: "Roboto Geo MT Bold";
			font-weight: bold;
			cursor: pointer;
			margin-left: 20px;
			&:focus {
				outline: none;
			}

			@media screen and (max-width: 1123px) {
				width: 280px;
				height: 50px;
				font-size: 20px;
				margin-bottom: 10px;
				margin-left: 0px;
			}
		}
	}
}
