.grade {
	width: calc(100% - 463px);
	position: relative;
	display: flex;
	margin-right: 20px;
	text-align: center;
	height: 90px;
	top: 12px;
	left: 260px;
	overflow: hidden;
	white-space: nowrap;
}

button:focus {
	outline: none;
}

.date {

	font-family: "Roboto GEO Nus" !important;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	/* identical to box height */

	text-align: left;
	margin-left: 30px;
	/* Dark Blue */
	width: 90%;
	color: #626A84;
}

.listBox {
	position: relative;
	display: flex;
	background-color: #fff;
	height: 80px;
	border-radius: 20px;
	width: 96%;
	margin-left: 26px;
	font-family: "Roboto GEO Nus";
	color: #58607C;
	font-size: 14px;
	margin-bottom: 20px;
}

.listIndex {
	font-family: "Roboto GEO Nus";
	font-style: normal;
	font-weight: bold;
	font-size: 23px;
	line-height: 27px;
	display: inline-block;
	width: 10px;
	color: #5273E6;
	margin: auto 0 auto 30px;
}

.studentInfo {
	font-family: Roboto Geo MT Bold, serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
	color: #58607C;
	margin: auto 0 auto 30px;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 1.2em;
	white-space: nowrap;
	width: 300px;
	min-width: 300px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;
}

.indexStyle {
	font-family: "Roboto GEO Nus", serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	text-align: left;
	color: #919191;

	position: relative;
	float: left;
}

input:focus {
	outline: none;
}

.studentCell {
	width: 70px;
	height: 40px;
	left: 861px;
	top: 490px;

	/* Dark Blue */

	border: 1px solid #626A84;
	box-sizing: border-box;
	border-radius: 10px;



	font-family: "Roboto GEO Nus";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */

	text-align: center;
	margin-right: 15px;
	color: #3A3D47;
}

.studentCell:focus {
	outline: none;
}

.studentCellWrapperDiv {
	position: relative;
	width: 50%;
	left: 13px;
	overflow: visible;
	display: inline-block;
}

.headerCellWrapper {
	width: 45.1%;
	margin-left: 165px;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
}

.romanHeader {
	width: 72px;
	height: 25px;

	background: #DEE3F1;
	border-radius: 8px;
	display: inline-block;
	color: #626A84;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height */
	padding-top: 5px;
	margin-right: 29px;
	text-align: center;
	font-family: "Roboto GEO Nus", serif;

	position: relative;
}


.rightHeaderIcon {
	display: flex;
	position: relative;
	cursor: pointer;
	float: right;
}


.totalSum {
	font-family: "Roboto GEO Nus", serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	text-align: left;
	position: absolute;
	left: 1030px;
	color: #919191;
}

.studentSum {
	width: 60px;
	height: 40px;
	font-family: "Roboto GEO Mt Bold";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */

	text-align: center;

	/* Grey Blue */

	background: #AEB5C9;
	border-radius: 10px;
	display: inline-block;


	color: #FFFFFF;
}

.studentAvg {

	background: #626A84;
	border-radius: 10px;
	display: inline-block;

	color: #FFFFFF;
	width: 60px;
	height: 40px;
	font-family: "Roboto GEO Mt Bold", serif;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */
	text-align: center;

}

.totalScore {

	background: #5273E6;
	border-radius: 10px;
	display: inline-block;

	color: #FFFFFF;
	width: 60px;
	height: 40px;
	font-family: "Roboto GEO Mt Bold";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */

	text-align: center;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.cellRight {
	border: 1px solid #626A84;
	line-height: 2px;
}

.generalCellsRight {
	background-color: #EFF1F7;
	display: inline-block;
	width: 2px;
	height: 80px;
	top: 4px;
	position: absolute;
	left: 55px;
}

.studentFinalInfoWrapper {
	display: inline-block;
	font-family: Roboto GEO Nus, serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	text-align: center;

	color: #919191;

	position: relative;
	height: 20px !important;
	width: 240px;
	top: -8px;
}

.totalsSection {
	margin-top: 20px;

	width: fit-content;
}

.statsButton {
	background: #37D7A0;
	border-radius: 15px;
	width: 247px;
	height: 45px;

	font-family: Roboto GEO Nus;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	/* identical to box height */

	text-align: center;
	float: right;
	/* White */
	outline: none;
	color: #FFFFFF;
}

.statsButton:focus {

	outline: none;
	box-shadow: none;

}

.statsDiv {
	background: #FFFFFF;
	border-radius: 20px;
	text-align: center;
	margin-left: 60px;
}

.statsHeader {
	font-family: Roboto GEO Mt Bold;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	color: #5273E6;
}

.statsSectionHeader {
	font-family: Roboto GEO Nus;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;

	color: #919191;
	text-align: center;

	margin-left: 60px;
}

.finalGrade {
	float: left !important;
	background: #EFF1F7;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	padding-top: 10px;
	margin: auto 0;

	margin-top: 15px;
	margin-left: 47px;
}

.finalSections {

	font-family: Roboto GEO Nus;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */

	position: relative;

	/* Dark Blue */

	color: #626A84;
	height: 70px;
	text-align: center;
	width: 87%;
}

.percentage {
	float: right;
	width: 100px;
	height: 40px;
	text-align: center;
	border-radius: 10px;
	background-color: #EFF1F7;
	padding-top: 11px;
	margin-top: 15px;
}


.fileInput {
	opacity: 0;
	position: absolute;
	z-index: -1;
	/* cursor: pointer !important; */
}

.fileInputLabel {
	/* cursor: pointer !important; */
	font-family: Roboto GEO Nus;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	width: 226px;
}

.inputSpan {
	/* cursor: pointer !important; */
	height: 45px;
	width: 226px;
	color: white;
	background: #5273E6;
	border-radius: 15px;
	float: right;
	margin-right: 15px;
	text-align: center;
	padding-top: 11px;
	position: relative;
}

.uploadText {
	display: inline-block;
	width: 165px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


@media only screen and (min-width: 1500px) {

	.rightHeaderIcon {
		right: 390px;
	}

	.statsDiv {
		margin-left: 80px;
	}

	.headerCellWrapper {
		width: 45%;
	}

	.studentCellWrapperDiv {
		width: 51%;
	}

	.listBox {
		width: 93%;
	}

	.romanHeader {
		margin-right: 30px;
	}

	.studentFinalInfoWrapper {
		margin-left: 46px;
	}

	.totalsSection {
		margin-left: 47px;
	}
}



.arrowBox {
	background: #ffffff;

	position: absolute;
	z-index: 999;
	display: inline-block;
	width: 220px;
	height: 50px;
	text-align: center;
	vertical-align: middle;
	border-radius: 15px;
	padding-top: 12px;
	left: -74px;
	top: 82px;
	/* border-style: solid;
	border-color: black; */
	color: white;
	font-family: Roboto GEO Nus !important;
	border: 3px solid #5273E6;
	background-color: #5273E6;
}

.arrowBox:after,
.arrowBox:before {
	bottom: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrowBox:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #5273E6;
	border-width: 19px;
	left: 50%;
	margin-left: -19px;
}

.arrowBox:before {
	border-bottom-color: #5273E6;
	border-width: 20px;
	left: 50%;
	margin-left: -20px;
}


@media screen and (max-width: 1251px) {
	.mainDiv {
		margin-left: 50px;
	}
}