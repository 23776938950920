$headerHeight: 70px;

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $headerHeight;
	z-index: 900;
	padding: 5px 30px;

	@media screen and (max-width: 544px) {
		padding-left: 8px;
		padding-right: 8px;
	}
}

.containerPlaceholder {
	height: $headerHeight;
}

.leftContainer {
	justify-content: center;
}

.rightContainer {
	position: relative;
}
.leftContainer,
.rightContainer {
	display: flex;
	align-items: center;
}

.logo {
	height: 100%;
	width: 110px;
	margin-top: -2px;
	cursor: pointer;

	@media screen and (max-width: 400px) {
		width: 80px;
	}
}

.profileContainer {
	position: relative;
	font-family: "Roboto Geo Caps";
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: 10px;
	margin-right: 10px;
}

.userStatusAndName {
	display: inline-block;
	vertical-align: middle;
	text-align: right;
	margin-right: 10px;

	@media screen and (max-width: 768px) {
		display: none;
	}
}

.profileIcon {
	position: relative;
	width: 50px;
	height: 50px;
	background: no-repeat;
	background-size: contain;
	padding: 8px;
	cursor: pointer;
	border-radius: 100%;
	transition: 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	background-color: white;
	border: #fff solid 2px;
	color: #fff;

	& > .img {
		width: 100%;
	}

	@media screen and (max-width: 1024px) {
		width: 45px;
		height: 45px;
	}
}

.alertIconContainer {
	position: absolute;
	top: -5px;
	right: 0;
	width: 17px;
	height: 17px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	background: #ef6b85;
	justify-content: center;
	color: white;
	cursor: pointer;
}

.mobileVerificationWarning {
	width: 268px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #626a84;
	font-family: "Roboto Geo Nus";
	padding: 9px;
	margin-top: 126px;
	position: absolute;
	right: 0;

	@media screen and (max-width: 718px) {
		margin-top: 92px;
	}

	@media screen and (max-width: 600px) {
		display: none;
	}
}

.burgerIcon {
	width: 24px;
	cursor: pointer;
}

.menu {
	position: absolute;
	width: 200px;
	right: 0px;
	margin-top: 30px;
}
