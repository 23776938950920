.container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 30px;
	font-family: "Roboto Geo Nus";

	@media screen and (max-width: 544px) {
		padding-left: 8px;
		padding-right: 8px;
	}

	.mainText {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-right: 10px;
	}

	.live {
		background: #ff332e;
		width: 8px;
		height: 8px;
		border-radius: 10px;
		margin-right: 10px;
	}

	button {
		font-family: "Roboto Geo Caps";
		border: 1px solid;
		padding: 4px 8px;
		border-radius: 6px;
	}
}
