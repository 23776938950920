$inner-margin-left: 85px;
$inner-margin-left-mb: 48px;

.container {
	img {
		max-width: 100%;
	}

	.qContainer {
		font-family: "Roboto Geo Nus";
		margin: 40px 0;
		text-decoration-skip-ink: none;

		@media screen and (max-width: 900px) {
			padding: 25px 10px;
			margin: 0;
			border-bottom: 1px solid #e5e5e5;
		}
	}

	.textContainer {
		font-family: "Roboto Geo Nus";
		margin: 40px 0;
		text-decoration-skip-ink: none;
	}

	.qUpperIcons {
		display: none;
	}

	@media screen and (max-width: 900px) {
		.qUpperIcons {
			display: flex;
			margin-bottom: 15px;
			justify-content: space-around;
		}

		.qInlineIcons {
			display: none;
		}
	}

	.qUpperIcons > button {
		border: none;
		border-radius: 12px;
		box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		padding: 6px 4px;
		transition: 0.3s;
		font-family: "Roboto Geo Nus";
		cursor: pointer;
		background: white;
		color: #626a84;
		outline: none;

		@media screen and (min-width: 360px) {
			padding: 7px 10px;
		}

		svg {
			margin-right: 3px;
		}

		&:hover,
		&:focus {
			background: #626a84;
			color: white;
		}

		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
			box-shadow: 0 0 0 1px rgba(98, 106, 132, 0.3);

			&:hover,
			&:focus {
				background: white;
				color: #626a84;
			}
		}
	}

	.textQuestionsContainer {
		padding-left: $inner-margin-left;

		@media screen and (max-width: 900px) {
			padding-left: $inner-margin-left-mb;
		}
	}

	.typeContainer {
		color: #626a84;
		font-size: 14px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		svg {
			width: 20px;
		}
	}

	.folding {
		color: #5273e6;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		display: flex;
		padding: 10px 0;
		margin-bottom: -10px;
		cursor: pointer;
	}

	.textStatContainer {
		transition: 0.3s;
		overflow: hidden;

		&.folded {
			position: relative;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 30px;
				background: linear-gradient(
					to bottom,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 1)
				);
			}
		}
	}

	.stat-container {
		border-radius: 15px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
		border: 1px solid transparent;
		padding: 20px 30px;
		display: flex;
		align-items: center;
		position: relative;

		@media screen and (max-width: 1400px) {
			border-radius: 10px;
			padding: 10px 30px;
		}

		@media screen and (max-width: 900px) {
			padding: 8px 15px;
		}
	}

	.stat-edit-container {
		border-radius: 15px;
		padding: 20px 30px;
		border: 1px solid #626a84;
		display: flex;
		align-items: center;
		position: relative;

		@media screen and (max-width: 1400px) {
			border-radius: 10px;
			padding: 10px 30px;
		}

		@media screen and (max-width: 900px) {
			padding: 8px 15px;
		}
	}

	.statementNumber {
		color: #5273e6;
		font-size: 30px;
		font-weight: bold;
		width: 45px;
		text-align: left;

		@media screen and (max-width: 900px) {
			font-size: 26px;
			width: 35px;
		}
	}

	.textNumContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 45px;
		font-family: "Roboto Geo Nus";

		.statementNumber {
			width: auto;
		}

		.separator {
			width: 2px;
			height: 30px;
			background: #5273e6;
		}

		@media screen and (max-width: 900px) {
			font-size: 26px;
			width: 35px;
		}
	}

	.statement {
		padding: 10px;
		flex: 1;
		word-break: break-word;

		@media screen and (max-width: 900px) {
			padding: 5px;
		}
	}

	.changeTitle {
		color: #626a84;
		cursor: pointer;
		font-size: 9px;

		@media screen and (max-width: 900px) {
			display: none;
		}
	}

	.changeContainer {
		position: absolute;
		right: -15px;
		top: 50%;
		transform: translate(100%, -50%);
		color: #626a84;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px !important;
	}

	.editIcon:hover {
		color: #37d7a0;
	}

	.deleteIcon:hover {
		color: #ef6b85;
	}

	.changeIcon,
	.editIcon,
	.deleteIcon {
		cursor: pointer;
	}

	.mcChoiceListContainer {
		margin-top: 15px;
		margin-left: $inner-margin-left;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;

		@media screen and (max-width: 900px) {
			grid-template-columns: 1fr;
			margin-left: $inner-margin-left-mb;
		}
	}

	.mcChoice,
	.mcChoiceEdit {
		border-radius: 15px;
		padding: 10px 20px;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid transparent;
		word-break: break-word;
		text-align: center;

		@media screen and (max-width: 1400px){
			border-radius: 10px;
			padding: 5px 20px;
			min-height: 60px;
		}

		@media screen and (max-width: 600px) {
			padding: 5px 10px;
		}
	}

	.mcChoice {
		color: white;

		&.correct {
			background: #37d7a0;
		}

		&.incorrect {
			background: #ef6b85;
		}
	}

	.mcChoiceEdit {
		color: #626a84;

		&.correct {
			border-color: #37d7a0;
		}

		&.incorrect {
			border-color: #ef6b85;
		}
	}

	.mcChoiceEdit {
		:global(.public-DraftEditorPlaceholder-root) {
			left: 50%;
			width: 100%;
			transform: translate(-50%);
		}
	}

	.siItemListContainer {
		margin-top: 15px;
		margin-left: $inner-margin-left;

		@media screen and (max-width: 900px) {
			margin-left: $inner-margin-left-mb;
		}
	}

	.siInnerItem {
		background: #b4c0e9;
		border: none;
		color: #4e5468;
		border-radius: 15px;
		padding: 5px 25px;
	}

	.siInnerItemCorrect {
		border: none !important;
	}

	.siInnerItemIcon {
		padding-right: 20px;
	}

	.explanation,
	.explanationEdit {
		margin-left: $inner-margin-left;
		border-radius: 18px;
		padding: 20px 60px 20px 40px;
		margin-top: 20px;
		border: 1px solid;
		word-break: break-word;

		@media screen and (max-width: 1400px) {
			padding: 10px 60px 10px 40px;
		}

		@media screen and (max-width: 900px) {
			margin-left: $inner-margin-left-mb;
		}

		.title {
			font-size: 14px;
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			svg {
				margin-right: 10px;
			}
		}
	}

	.explanation {
		background: #e5e5e5;
		color: #626a84;
		border-color: transparent;
	}

	.explanationEdit {
		background: transparent;
		color: #626a84;
		border-color: #e5e5e5;
	}

	.statText {
		width: 100%;
	}

	.choiceStatEditContainer {
		width: 100%;
		text-align: center;
	}

	.explanationEditorContainer {
		padding: 0px;
		border-radius: 0px;
		border: 0px;
	}

	.saveButton {
		font-family: "Roboto Geo MT Bold";
		color: #5273e6;
		width: 284px;
		border: solid 1px #5273e6;
		background-color: white;
		padding: 10px 30px;
		display: flex;
		align-items: center;
		font-size: 18px;
		justify-content: center;
		border-radius: 13px;
		cursor: pointer;
		outline: none;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		margin: 30px auto;
	}

	.newQIcon {
		color: #626a84;
		width: 32px;
	}

	.fbStatementEditContainer {
		width: 100%;
		margin: 0;
		padding: 10px;
		border: 1px solid #626a84;
		border-radius: 15px;
	}

	.fbStatementEditText {
		width: 100%;

		:global(.DraftEditor-root) {
			width: 100%;
		}

		:global(.public-DraftEditor-content) {
			min-height: 100px;
		}
	}
}

.filesContainer {
	margin: 5px 0;

	.filesTitle {
		font-family: "Roboto Geo MT Bold";
		margin-top: 10px;
		font-size: 12px;
		color: #626a84;
	}
}
