.mainContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	margin-top: -110px;
}

.candidatesListContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.firstStepText {
	font-family: "Roboto Geo Nus";
	font-size: 14px;
	text-align: left;
	color: #626a84;
	margin-bottom: 12px;
}

.firstStepContainer {
	margin-top: 33px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
}

.undestoodButton {
	margin-top: 27px;
	width: 300px;
	height: 55px;
	border-radius: 15px;
	background-color: #5273e6;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	color: white;
}

.awardContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 220px;
	height: 220px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	border-radius: 125px;
}

.tab {
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	color: #626a84;
	margin-left: 15px;
	cursor: pointer;
}

.selectedTab {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 15px;
	height: 50px;
	border-radius: 20px;
	background-color: #5273e6;
	margin-left: 15px;
	color: white;
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	cursor: pointer;
}

.tabsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}
