.cometChatParent {
	&.hideme {
		display: none;
	}

	position: fixed;
	display: flex;
	z-index: 1000;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	top: 0px;
	left: 0px;
	background-color: rgba(255, 255, 255, 0.507);

	.cometChatBox {
		position: absolute;
		// top: 130px;
		background-color: rgb(255, 255, 255);
		border-radius: 20px;
		box-shadow: 0 0 6px 0 rgb(148, 148, 148);

		height: 75%;
		width: 60%;

		@media screen and (max-width: 700px) {
			top: 75px;
			width: calc(100% - 27px);
		}

		@media screen and (max-height: 500px) {
			top: 3px;
			width: calc(100% - 27px);
			height: calc(100% - 62px);
		}
	}
}

// .popupTopRibbon {
// 	position: relative;
// 	// outline: 1px solid red;
// 	height: 47px;

// 	.closeButton {
// 		position: absolute;
// 		top: 50%;
// 		right: 10px;
// 		transform: translateY(-50%);
// 		// transform: translateY(-50%);
// 		// outline: 1px solid rgb(255, 0, 221);
// 		background-color: rgb(240, 240, 240);
// 		width: 32px;
// 		height: 32px;
// 		border-radius: 177px;

// 		&:hover {
// 			cursor: pointer;
// 			background-color: rgb(223, 223, 223);
// 		}

// 		.closeIcon {
// 			position: absolute;
// 			top: 50%;
// 			left: 50%;
// 			transform: translate(-50%, -50%);
// 			width: 13px;
// 			height: 13px;
// 			fill: #626a84;
// 		}
// 	}
// }

.chatIconAndNotif {
	position: relative;
	width: 50px;
	height: 50px;
	margin-right: 10px;
	.chatIconBox {
		position: relative;
		display: flex;
		align-self: center;

		width: 50px;
		height: 50px;
		border-radius: 100px;
		overflow: hidden;
		cursor: pointer;

		@media screen and (max-width: 700px) {
			width: 46px;
			height: 46px;
		}

		&:hover {
			box-shadow: 0 0 4px 2px #66b5ff;
		}

		.chatmeIcon {
			width: 100%;
		}
	}

	@media screen and (max-width: 700px) {
		width: 46px;
		height: 46px;
	}

	.unreadNotif {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: -5px;
		bottom: -14px; // for animation to -18
		width: 28px;
		height: 28px;
		font-weight: bold;
		color: white;
		border-radius: 300px;
		font-family: "FiraGO";
		font-size: 16px;
		// background-color: #ff7b00;
		background-image: linear-gradient(62deg, #ff5e00 0%, #fac02e 100%);
		border: 1px solid rgb(255, 121, 121);
		// transition: all 1s;

		-webkit-animation: slide 2s forwards;
		// -webkit-animation-delay: 2s;
		animation: slide 2s forwards;
		// animation-delay: 0.3s;
	}
}
@-webkit-keyframes slide {
	0% {
		bottom: -300px;
		right: 200px;
		width: 80px;
		height: 80px;
		font-size: 35px;
	}
	100% {
		bottom: -14px;
		right: -5px;
		width: 28px;
		height: 28px;
		font-size: 16px;
	}
}

@keyframes slide {
	0% {
		bottom: -300px;
		right: 200px;
		width: 80px;
		height: 80px;
		font-size: 35px;
	}
	100% {
		bottom: -14px;
		right: -5px;
		width: 28px;
		height: 28px;
		font-size: 16px;
	}
}
