.container {
	width: 1000px !important;
	background: url("/imgs/kings/subjs-wallpaper.svg") top -10px right -5px,
		url("/imgs/kings/fancy-logo.svg") bottom -11px right -5px;
	background-size: 360px auto, 150px auto;
	background-repeat: no-repeat;

	@media screen and (max-width: 980px) {
		background: none;
	}
}
