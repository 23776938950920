@function em($pixels, $context: 16) {
	@return #{$pixels/$context}em;
}

.checkBoxDiv {
	height: 2.8125em;
	flex: 1;
	background-color: white;
	border-radius: em(10);
	text-align: center;
	color: #6a6c73;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: em(10);
	cursor: pointer;
	font-family: "Roboto Geo Caps";
	transition: all 0.2s;
	&:hover {
		box-shadow: 0 0 0 2px #5273e6;
	}
}

.square {
	height: em(30);
	width: em(30);
	background-color: #eff1f7;
	display: block;
	border-radius: em(7);
	padding: em(6);
	float: left;
	text-align: center;
}

.innerCheckbox {
	width: 100%;
	height: 100%;
	background-color: #5273e6;
	border-radius: 25%;
	vertical-align: middle;
	position: relative;
}
