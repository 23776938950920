.popup_container {
	/* position: relative;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff; */
	padding: 40px 90px 50px;
	border-radius: 30px;
	// border: 1px solid black;
	width: 1200px !important;

	@media screen and (max-width: 1500px) {
		top: 40px;
		width: 1100px !important;
		padding: 20px 50px;
	}
	@media screen and (max-width: 1200px) {
		width: 960px !important;
		padding: 20px 40px;
	}
	@media screen and (max-width: 1024px) {
		padding: 50px 20px;
		width: 355px !important;
	}
	@media screen and (max-width: 360px) {
		padding: 50px 10px;
		width: 300px !important;
	}
}
.teacherImg {
	background-image: url("../../../../../icons/teacherInvitationIcon.svg");
	background-repeat: no-repeat;
	background-size: 53%;
	background-position: 123% -157px;

	@media screen and (max-width: 1200px) {
		background-size: 51%;
		background-position: 122% -155px;
	}
	@media screen and (max-width: 1024px) {
		background-image: url("../../../../../icons/TeacherInvitationMobile.svg");
		background-size: 105%;
		background-position: right -10px top -105px;
	}
}
.studentImg {
	background-image: url("../../../../../icons/studentInvitationIcon.svg");
	background-repeat: no-repeat;
	background-size: 53%;
	background-position: 128% 20px;

	@media screen and (max-width: 1200px) {
		background-position: 128% 50px;
	}

	@media screen and (max-width: 1024px) {
		background-image: url("../../../../../icons/StudentInvitationMobile.svg");
		background-size: 109%;
		background-position: 48% -83%;
	}

	@media screen and (max-width: 640px) {
		background-position: 48% -1300%;
	}

	@media screen and (max-width: 360px) {
		background-position: 48% -76%;
	}
}
.popup_title__bold {
	font-family: "Roboto Geo MT Bold";
	font-size: 22px;
	color: #626a84;
	margin-bottom: 40px;
	letter-spacing: 0.3px;

	@media screen and (max-width: 1500px) {
		font-size: 20px;
		margin-bottom: 20px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 18px;
		color: #fff;
		margin-bottom: 50px;
	}
	@media screen and (max-width: 360px) {
		font-size: 15px;
		margin-bottom: 50px;
	}
}
.popup_paragraph {
	font-family: "Roboto Geo Nus";
	font-size: 18px;
	color: #626a84;
	margin-bottom: 16px;

	@media screen and (max-width: 1500px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
	@media screen and (max-width: 1024px) {
		width: 60%;
		text-align: right;
		font-size: 14px;
		color: #3a3d47;
		margin-left: auto;
	}
	@media screen and (max-width: 360px) {
		width: 60%;
		font-size: 12px;
	}
}
.popup_title__normal {
	font-family: "Roboto Geo Caps";
	font-size: 22px;
	color: #3a3d47;
	margin-bottom: 10px;
	margin-top: 30px;

	@media screen and (max-width: 1500px) {
		font-size: 18px;
		margin-bottom: 20px;
	}
	@media screen and (max-width: 1024px) {
		text-align: center;
		font-size: 16px;
		margin-top: 10px;
		margin-bottom: 0;
	}
	@media screen and (max-width: 360px) {
		font-size: 15px;
	}
}
.list {
	width: 65%;
	&_item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&__line {
			width: 20px;
			height: 6px;
			background: #5273e6;
			border-radius: 3px;
			margin-right: 10px;

			@media screen and (max-width: 360px) {
				width: 16px;
				height: 5px;
			}
		}
		&__text {
			font-family: "Roboto Geo Nus";
			font-size: 18px;
			color: #626a84;
			margin-bottom: 10px;
			width: calc(100% - 30px);

			@media screen and (max-width: 1500px) {
				font-size: 16px;
			}
			@media screen and (max-width: 1024px) {
				font-size: 14px;
				line-height: 18px;
			}
			@media screen and (max-width: 360px) {
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
	@media screen and (max-width: 1500px) {
		width: 70%;
	}
	@media screen and (max-width: 1024px) {
		margin-top: 70px;
		width: 100%;
	}
	@media screen and (max-width: 360px) {
		margin-top: 50px;
	}
}
.text_bold {
	color: #3a3d47;
	font-weight: bold;
}
