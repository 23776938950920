.mathEquationsExplanationClosed {
	display: flex;
	align-items: center;
	color: #626a84;
	font-size: 20px;
	cursor: pointer;
	font-family: "Roboto Geo Nus";
	margin: 22px 0px -26px 23px;
	transition: all 0.3s;
	
	.downIcon {
		margin-left: 5px;
		width: 34px;
		height: 34px;
		cursor: pointer;
		color: #626a84;
	}
	.sigmaIcon {
		width: 17px;
		margin-right: 12px;
	}

	@media screen and (max-width: 1400px){
		font-size: 16px;
		margin: 15px 0px -26px 20px;
	}
}
.arrow {
	color: #5273e6;
}
@keyframes grow {
	0% {
		height: 50px;
		opacity: 0;
		overflow: hidden;
	}
	10% {
		height: 65px;
		opacity: 0.09;
		overflow: hidden;
	}

	20% {
		height: 80px;
		opacity: 0.3;
		overflow: hidden;
	}
	30% {
		height: 95px;
		opacity: 0.5;
		overflow: hidden;
	}
	40% {
		height: 110px;
		opacity: 0.7;
		overflow: hidden;
	}
	50% {
		height: 125px;
		opacity: 0.8;
	}
	60% {
		height: 140px;
	}
	70% {
		height: 155px;
		opacity: 1;
	}
	80% {
		height: 170px;
	}
	100% {
		height: auto;
	}
}
.mathEquationsExplanation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: "Roboto Geo Caps";
	align-items: center;
	width: 100%;
	height: auto;
	max-width: 1488px;
	border-radius: 20px;
	padding: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	font-size: 20px;
	color: #626a84;
	margin: 20px 0px -14px 0px;
	animation: grow 0.6s;
	overflow: hidden;

	.instructions {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: wrap;
	}
	.instructionBoxContainer {
		display: flex;
		font-size: 14px;
		justify-content: center;
		align-items: center;
		margin-bottom: 14px;

		.instructionBox {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.equationContainer {
				margin-left: 5px;
				font-size: 15px;
			}
		}
		.divider {
			width: 2px;
			height: 51px;
			background-color: gray;
			margin: 10px 23px;
		}
	}
	.closeIcon {
		width: 34px;
		height: 34px;
		align-self: flex-end;
		margin-top: -16px;
		cursor: pointer;
		color: #626a84;
	}
	.explanation {
		font-family: "Roboto Geo MT Bold";
		margin-bottom: 20px;
	}
	.fullInstruction {
		margin-top: 15px;
		font-family: "Roboto  Geo Nus";
		font-size: 16px;
		color: #5273e6;
		cursor: pointer;
	}

	@media screen and (max-width: 1400px){
		max-width: 1300px;
		border-radius: 15px;
		padding: 10px;
		font-size: 16px;
		margin: 10px 0px -14px 0px;

		.closeIcon {
			width: 30px;
			height: 30px;
			margin-top: -10px;
		}

		.explanation {
			font-size: 16px;
			margin-bottom: 10px;
		}

		.fullInstruction {
			margin-top: 15px;
			font-size: 16px;
		}
	}
}
.transition-enter {
	opacity: 0;
	transform: scale(0.9);
}
.transition-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.transition-exit {
	opacity: 1;
}
.transition-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
