.popupContent {
	width: 500px;
	max-width: 90%;

	@media screen and (min-width: 900px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.title {
	font-family: "Roboto Geo MT Bold";
	color: #5273e6;
	font-size: 20px;
	margin-top: 0;
}

.secondaryTitle,
.biggerSecondaryTitle {
	font-family: "Roboto Geo Nus";
	display: block;
	color: #626a84;
	font-size: 15px;
}

.biggerSecondaryTitle {
	font-size: 18px;
}

.textarea {
	resize: none;
	width: 100%;
	border-radius: 15px;
	border: solid 1px #5273e6;
	outline: none;
	min-height: 100px;
	padding: 10px;
	margin: 16px 0;
}
