.studentAnswerOnNonCheckableInputContainer {
	margin-top: 10px;
	border-top: 1px solid #ddd;
	padding: 10px 0;

	.title {
		font-family: "Roboto Geo MT Bold";
		color: #5273e6;
		font-size: 14px;
	}
	.userAnswerCSS {
		color: #626a84;
		font-family: "Roboto Geo Nus";
		margin-top: 10px;
		word-break: break-all;
		word-break: break-word;
	}
}

.userInput {
	display: flex;
	width: 100%;
	margin: 10px 0;
	padding: 10px;
	border: 1px solid #626a84;
	border-radius: 15px;

	& > svg {
		width: 25px;
		margin-right: 10px;
		align-self: flex-start;
		color: #b7b7b7;
	}

	& > textarea {
		width: 100%;
		resize: none;
		border: none;
		outline: none;
		font-size: 16px;
		font-family: "Roboto Geo Nus";
		color: #626a84;
		height: 100px;
	}

	&.Large {
		& > textarea {
			height: 120px;
		}
	}
	&.ExtraLarge {
		& > textarea {
			height: 220px;
		}
	}
}

.assessmentScore {
	font-family: "Roboto Geo Nus";
	color: #1f7faf;
	font-size: 22px;
	span {
		color: #1d98d4;
		font-size: 27px;
	}
}
