.popupContainer {
	width: 80%;
}

.certificateContainer {
	display: flex;
	justify-content: center;
}

.certificateBox {
	padding: 10px;
	width: 801.5px;
	border-radius: 10px;
	border: solid 3px #5273e6;
	position: relative;
}
.certifacte {
	padding: 0px;
	margin: 0px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.congrats {
	display: flex;
	justify-content: center;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	color: #5273e6;
	margin-bottom: 15px;
	text-align: center;
}

.inputTitle {
	font-family: "Roboto Geo Nus";
	font-size: 16px;
	color: #626a84;
	text-align: center;
	margin-bottom: 10px;
}

.text {
	margin-left: 30px;
	margin-right: 30px;
	font-family: "Roboto Geo Nus";
	font-size: 16px;
	color: #626a84;
	text-align: center;
	margin-bottom: 10px;
}

.nameInput {
	padding-left: 5px;
	font-family: "Roboto Geo Nus";
	font-size: 20px;
	color: #626a84;
	margin-bottom: 10px;
	width: 400px;
	height: 50px;
	border-radius: 5px;
	border: solid 1px #5273e6;
	background-color: white;
}

.saveButton {
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #5273e6;
	text-align: center;
	margin-left: 10px;
	text-decoration: underline;
}

.buttonsContainer {
	margin-top: 15px;
	display: flex;
	justify-content: center;
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	.shareButton {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 300px;
		height: 55px;
		border-radius: 15px;
		background-color: #5273e6;
		color: white;
		text-align: center;
		margin-right: 10px;
	}
	.downloadButton {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 300px;
		height: 55px;
		border-radius: 15px;
		background-color: white;
		border: solid 1px #5273e6;
		color: #5273e6;
		text-align: center;
		margin-right: 10px;
		.st0 {
			fill: #5273e6;
		}
	}
}

.notQualified {
	.buttonsContainer {
		filter: grayscale(1);
		opacity: 0.4;
		cursor: not-allowed;

		.shareButton,
		.downloadButton {
			cursor: not-allowed;
		}
	}
	.text {
		margin-bottom: 40px;
	}
}

.lock {
	background: url("/imgs/padlock.svg"), rgba(255, 255, 255, 0.6);
	background-position: top 31% center;
	background-repeat: no-repeat;
	background-size: auto 30%;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 6px;
}
